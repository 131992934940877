<template>
  <div>
    <!-- begin:: Header -->
    <div
      id="kt_header"
      ref="kt_header"
      class="header"
      v-bind:class="headerClasses"
    >
      <div
        class="container-fluid d-flex align-items-center justify-content-between"
      >
        <!-- begin:: Header Menu -->
        <div
          class="header-menu-wrapper header-menu-wrapper-left"
          ref="kt_header_menu_wrapper"
        >
          <div
            v-if="headerMenuEnabled"
            id="kt_header_menu"
            ref="kt_header_menu"
            class="header-menu header-menu-mobile"
            v-bind:class="headerMenuClasses"
          >
            <!-- example static menu here -->
            <ul
              class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
            >
              <template>
                <li class="breadcrumb-item">
                  <span style="font-size: 12px;">
                    TRANSAKSI BANK / BANK GARANSI EKSTERNAL / FORMULIR PENGAJUAN / <span style="font-weight: 600;">OPERASIONAL</span>
                  </span>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <!-- end:: Header Menu -->
        <!-- begin:: Header Topbar -->
        <div class="topbar">
          <!--begin: User Bar -->
          <div class="topbar-item">
            <!--begin: Notifications -->
            <b-dropdown
              size="m"
              variant="link"
              toggle-class="topbar-item text-decoration-none"
              no-caret
              right
              no-flip
              class="px-2 mr-4"
            >
              <template v-slot:button-content>
                <notification-bell
                  :size="20"
                  :count="totalNotification"
                  :ding="true"
                  :upperLimit="50"
                  counterLocation="upperRight"
                  counterStyle="roundRectangle"
                  counterBackgroundColor="#FF0000"
                  counterTextColor="#FFFFFF"
                  iconColor="#1e2758"
                  :animated="false"
                  fontSize="120"
                />
              </template>
              <b-dropdown-text tag="div" class="min-w-md-350px">
                <form>
                  <b-tabs>
                    <b-tab>
                      <perfect-scrollbar
                        class="navi navi-hover scroll my-4"
                        style="max-height: 40vh; position: relative;"
                      >
                        <router-link
                          :to="{
                            name: 'bank-in',
                            params: {
                              bankTransactionPermission: 'BM'
                            }
                          }"
                          v-slot="{ href, navigate }"
                          v-if="count_escrow_notification > 0"
                        >
                          <a
                            :href="href"
                            class="navi-item"
                            v-for="i in data_escrow_notification"
                            v-bind:key="i"
                            @click="navigate"
                          >
                            <div class="navi-link">
                              <div class="navi-icon mr-2">
                                <i class="flaticon2-bell"></i>
                              </div>
                              <div class="navi-text">
                                <div class="font-weight-bold">
                                  ESCROW
                                </div>
                                <div class="text-muted">
                                  ESCROW DITERIMA
                                </div>
                              </div>
                            </div>
                          </a>
                        </router-link>
                        <router-link
                          :to="{
                            name: 'guarantee'
                          }"
                          v-slot="{ href, navigate }"
                          v-if="count_bank_guarantee_notification > 0"
                        >
                          <a
                            :href="href"
                            class="navi-item"
                            v-for="i in data_bank_guarantee_notification"
                            v-bind:key="i"
                            @click="navigate"
                          >
                            <div class="navi-link">
                              <div class="navi-icon mr-2">
                                <i class="flaticon2-bell"></i>
                              </div>
                              <div class="navi-text">
                                <div class="font-weight-bold">
                                  PENCAIRAN
                                </div>
                                <div class="text-muted">
                                  PENCAIRAN BANK GARANSI
                                </div>
                              </div>
                            </div>
                          </a>
                        </router-link>
                        <router-link
                          :to="{
                            name: 'finance'
                          }"
                          v-slot="{ href, navigate }"
                          v-if="count_submission_form_finance_notification > 0"
                        >
                          <a
                            :href="href"
                            class="navi-item"
                            v-for="i in data_submission_form_finance_notification"
                            v-bind:key="i"
                            @click="navigate"
                          >
                            <div class="navi-link">
                              <div class="navi-icon mr-2">
                                <i class="flaticon2-bell"></i>
                              </div>
                              <div class="navi-text">
                                <div class="font-weight-bold">
                                  PENCAIRAN KE BANK GARANSI
                                </div>
                                <div class="text-muted">
                                  PENCAIRAN KE BANK GARANSI
                                </div>
                              </div>
                            </div>
                          </a>
                        </router-link>
                      </perfect-scrollbar>
                    </b-tab>
                  </b-tabs>
                </form>
              </b-dropdown-text>
            </b-dropdown>
            <!--end: Notifications -->
            <div
              class="btn btn-icon w-auto d-flex align-items-center btn-lg px-2"
              id="kt_quick_user_toggle"
            >
              <span
                class="symbol symbol-30 symbol-circle symbol-xl-30 pr-4 symbol-light-success"
              >
                <img v-if="false" alt="Profile Photo" :src="currentUserPhoto" />
                <span
                  v-if="true"
                  class="symbol-label font-size-h5 font-weight-bold"
                >
                  {{ user_fullname.charAt(0).toUpperCase() }}
                </span>
              </span>
              <span
                class="d-none d-md-inline mr-12"
                v-bind:style="{
                  'font-family': 'lato',
                  'font-size': '12px',
                  'font-weight': '600'
                }"
              >
                <span class="font-weight-bold font-size-base d-md-inline mr-1">
                  HI,
                </span>
                {{ user_fullname }}
              </span>
              <i class="ki ki-arrow-down icon-xs text-muted mr-12"></i>
            </div>

            <div
              id="kt_quick_user"
              ref="kt_quick_user"
              class="offcanvas offcanvas-right p-10"
            >
              <!--begin::Header-->
              <div
                class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
              >
                <h3 class="font-weight-bold m-0">
                  PROFIL USER
                </h3>
                <a
                  href="#"
                  class="btn btn-xs btn-icon btn-light btn-hover-primary"
                  id="kt_quick_user_close"
                >
                  <i class="ki ki-close icon-xs text-muted"></i>
                </a>
              </div>
              <!--end::Header-->

              <!--begin::Content-->
              <perfect-scrollbar
                class="offcanvas-content pr-5 mr-n5 scroll"
                style="max-height: 90vh; position: relative;"
              >
                <!--begin::Header-->
                <div class="d-flex align-items-center mt-5">
                  <div class="symbol symbol-100 mr-5">
                    <img :src="currentUserPersonalInfo.photo" alt="" />
                    <i class="symbol-badge bg-success"></i>
                  </div>
                  <div class="d-flex flex-column">
                    <span class="font-weight-bold font-size-h5 text-dark-75">
                      {{ user_fullname }}
                    </span>
                    <div class="navi mt-2">
                      <span class="navi-link p-0 pb-2">
                        <span class="navi-icon mr-1">
                          <span class="svg-icon svg-icon-lg svg-icon-primary">
                            <!--begin::Svg Icon-->
                            <inline-svg src="assets/icons/Mail.svg" />
                            <!--end::Svg Icon-->
                          </span>
                        </span>
                        <span class="navi-text text-muted">
                          {{ email }}
                        </span>
                      </span>
                    </div>
                    <button
                      class="btn btn-light-primary btn-bold"
                      @click="onLogout"
                    >
                      SIGN OUT
                    </button>
                  </div>
                </div>
                <!--end::Header-->
                <div class="separator separator-dashed mt-8 mb-5"></div>
                <div class="separator separator-dashed my-7"></div>
              </perfect-scrollbar>
              <!--end::Content-->
            </div>
          </div>
          <!--end: User Bar -->
        </div>
        <!-- end:: Header Topbar -->
      </div>
    </div>
    <!-- end:: Header -->
    <!-- Body -->
    <div style="margin-bottom: 43px;">
      <div>
        <img class="mb-4" src="assets/background_page_title.png" alt="" style="height: 36px; margin-right: 10px;">
        <span style="font-size: 30px; font-weight: 700;">
          OPERASIONAL
        </span>
      </div>
      <div>
        <span>
          MENAMPILKAN DAN MEMBUAT DATA FORMULIR OPERASIONAL BARU
        </span>
      </div>
    </div>
    <div class="form-group">
      <template>
        <v-toolbar flat>
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="white"
                light
                class="mb-12"
                v-bind="attrs"
                v-on="on"
                style="text-transform: capitalize !important; min-height: 48px;"
              >
                BUAT FORM BARU +
              </v-btn>
            </template>
            <v-card v-if="formInput == add_submission_form">
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-col>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >TANGGAL PENGAJUAN<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-menu
                          ref="date_submission_form"
                          v-model="date_submission_form"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          full-width
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formInputNotClear.date_submission"
                              class="vuetify-custom"
                              solo
                              v-bind:style="{
                                color: '#c30e0e !important'
                              }"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date_submission"
                            no-title
                            @input="date_submission_form = false"
                            @change="onChangeDate(date_submission)"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >TANGGAL TERBIT<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-menu
                          ref="date_published_submission_form"
                          v-model="date_published_submission_form"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          full-width
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formInputNotClear.date_published"
                              class="vuetify-custom"
                              solo
                              v-bind:style="{
                                color: '#c30e0e !important'
                              }"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date_published"
                            no-title
                            @input="date_published_submission_form = false"
                            @change="onChangeDatePublished(date_published)"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >TANGGAL BERAKHIR<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-menu
                          ref="date_end_submission_form"
                          v-model="date_end_submission_form"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          full-width
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formInputNotClear.date_end"
                              class="vuetify-custom"
                              solo
                              v-bind:style="{
                                color: '#c30e0e !important'
                              }"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date_end"
                            no-title
                            @input="date_end_submission_form = false"
                            @change="onChangeDateEnd(date_end)"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >PERUSAHAAN<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.workgroup_id"
                          id="workgroup_id"
                          :items="data_workgroup_combobox"
                          @click="dataWorkgroup_Combobox"
                          item-value="workgroup_id"
                          item-text="workgroup_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;">
                          PERUSAHAAN MITRA<span style="color: red;">*</span>
                        </span>
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.workgroup_partner_id"
                          id="workgroup_id"
                          :items="data_workgroup_combobox"
                          @click="dataWorkgroup_Combobox"
                          item-value="workgroup_id"
                          item-text="workgroup_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >PROYEK<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.project_id"
                          id="project_id"
                          :items="data_project_combobox"
                          @click="dataProject_Combobox"
                          item-value="project_id"
                          item-text="project_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                        <v-row>
                          <v-col cols="3" class="mt-4">
                            <span style="color: black; font-size: 14px;">
                              UPLOAD<span style="color: red;">*</span>
                            </span>
                          </v-col>
                          <v-col cols="6">
                            <v-file-input
                              accept="image/*"
                              type="file"
                              @change="onImageChange"
                              v-model="formInput.path_image_project"
                              show-size
                              counter
                              :clearable="false"
                            ></v-file-input>
                            <div
                              v-if="
                                this.add_submission_form.path_image_project2 !=
                                  null
                              "
                            >
                              <img
                                :src="add_submission_form.path_image_project2"
                                style="max-width: 200px;"
                              />
                            </div>
                            <div v-else>
                              <img
                                :src="editedItem.path_image_project"
                                style="max-width: 200px;"
                              />
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-8">
                        <span style="color: black; font-size: 14px;"
                          >NOMINAL<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <vuetify-money
                          v-model="formInput.amount"
                          v-bind:placeholder="placeholder"
                          v-bind:readonly="readonly"
                          v-bind:disabled="disabled"
                          v-bind:outlined="outlined"
                          v-bind:clearable="clearable"
                          v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                          v-bind:options="option"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  class="mb-4 btn btn-outline-secondary"
                  @click="close"
                >
                  BATALKAN
                </v-btn>
                <v-btn
                  color="primary"
                  light
                  class="mb-4"
                  @click="formSubmit"
                  style="text-transform: capitalize !important; color: white !important;"
                >
                  SIMPAN FORM BARU
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="CARI FORM PENGAJUAN"
            solo
            hide-details
            class="mb-12"
            style="width: 10px; box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);"
          ></v-text-field>
          <v-dialog v-model="dialogSeenBill" max-width="800px">
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-col>
                    <div>
                      <span class="text-h6">FILE FOTO</span>
                    </div>
                    <v-col class="text-center" v-for="(data) in data_files" :key="data.submission_number">
                      <div v-if="data.path_image_project_extention == 'png' || data.path_image_project_extention == 'jpeg' || data.path_image_project_extention == 'jpg' || data.path_image_project_extention == 'gif'">
                        <img :src="data.path_image_project" alt="Foto" style="max-width: 700px;" />
                      </div>
                    </v-col>
                  </v-col>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialogSeenBill = false">
                  TUTUP
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >APAKAH YAKIN UNTUK MENGHAPUS DATA INI?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDelete">BATAL</v-btn>
                <v-btn
                  text
                  @click="deleteDataSubmissionForm"
                  v-bind:style="{
                    color: '#1e2758 !important'
                  }"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="data_submission_form"
          :item-key="submission_number"
          :options.sync="options"
          :search="search"
          sort-by="Id"
          class="elevation-1 mb-12"
          :loading="loadingTable"
          loading-text="HARAP TUNGGU..."
        >
          <template v-slot:[`item.number`]="{ index }">
            <td>
              {{
                options.page * options.itemsPerPage -
                  options.itemsPerPage +
                  (index + 1)
              }}
            </td>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <td>Rp {{ formatNumber(item.amount) }}</td>
          </template>
          <template v-slot:[`item.submission_status`]="{ item }">
            <td v-if="item.submission_status == ''">-</td>
            <td v-else>{{ item.submission_status }}</td>
          </template>
          <template v-slot:[`item.path_image_project`]="{ item }">
            <v-btn
              color="blue"
              light
              small
              class="mr-3"
              @click="seenFileBill(item)"
              data-toggle="tooltip"
              title="LIHAT"
              style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
            >
              <v-icon small color="white">
                  mdi-eye
                </v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.date_published`]="{ item }">
            <td>
              {{ item.date_published == null ? "-" : item.date_published }}
            </td>
          </template>
          <template v-slot:[`item.date_end`]="{ item }">
            <td>
              {{ item.date_end == null ? "-" : item.date_end }}
            </td>
          </template>
          <template v-slot:[`item.aksi`]>
            <span v-if="user_role.toLowerCase() === 'atasan'">
              <v-icon small color="black">
                flaticon2-line
              </v-icon>
            </span>
            <span v-else-if="user_role.toLowerCase() === 'atasan operasional'">
              <v-icon small color="black">
                flaticon2-line
              </v-icon>
            </span>
            <span v-else>
              <v-icon small color="black">
                flaticon2-line
              </v-icon>
            </span>
          </template>
        </v-data-table>
      </template>
    </div>
    <!-- End Body -->
  </div>
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<style scoped>
.vuetify-custom >>> .v-text-field__slot label {
  color: #1e2758 !important;
}

.vuetify-custom >>> .v-select__slot label {
  color: #1e2758 !important;
}

.vuetify-custom >>> .v-input__icon i {
  color: #1e2758 !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import NotificationBell from "vue-notification-bell";
import localStorage from "@/core/services/store/localStorage";
import Services from "@/core/services/app_internal/Services";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import "@/core/plugins/vuetify-money.js";
import { formatNumber } from "@/helpers/helper.js";
import { formatDate } from "@/helpers/helper.js";

export default {
  name: "submission-form",

  components: {
    NotificationBell
  },

  data: () => ({
    search: "",
    dialog: false,
    dialogDelete: false,
    dialogSeenBill: false,
    create_data_submission_form: [],
    update_data_submission_form: [],
    delete_data_submission_form: [],
    create_data_voucher: [],
    data_submission_form: [],
    data_files: [],
    data_escrow_notification: [],
    data_bank_guarantee_notification: [],
    data_submission_form_finance_notification: [],
    data_workgroup_combobox: [],
    data_organizational_units_combobox: [],
    data_project_combobox: [],
    data_work_units_combobox: [],
    data_submission_form_date: {
      date_submission: new Date().toISOString().substr(0, 10),
      date_published: new Date().toISOString().substr(0, 10),
      date_end: new Date().toISOString().substr(0, 10),
      date: new Date().toISOString().substr(0, 10)
    },
    add_submission_form: {
      workgroup_id: "",
      workgroup_partner_id: "",
      organizational_unit_id: "",
      work_unit_id: "",
      project_id: "",
      date_submission: new Date().toISOString().substr(0, 10),
      date_published: new Date().toISOString().substr(0, 10),
      date_end: new Date().toISOString().substr(0, 10),
      submission_desc: "",
      submission_type: "",
      submission_permission: "",
      transaction_type: "",
      amount: 0,
      submission_status: "",
      path_image_project: null,
      path_image_project2: null
    },
    add_submission_form_not_null: {
      date_submission_form: false,
      date_published_submission_form: false,
      date_end_submission_form: false,
      date_submission: formatDate(),
      date_published: formatDate(),
      date_end: formatDate()
    },
    submitted: false,
    headers: [],
    editedIndex: -1,
    deletedIndex: -1,
    editedItem: {},
    deletedItem: {},
    defaultItem: {},
    isFirstLoad: true,
    options: {
      page: 1,
      itemsPerPage: 5,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: true,
      mustSort: false
    },
    label: "",
    placeholder: "",
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: false,
    valueWhenIsEmpty: "",
    option: {
      locale: "en-US",
      prefix: "",
      suffix: "",
      length: 20,
      precision: 0
    },
    date_submission: "",
    date_published: "",
    date_end: "",
    date: "",
    user_role: "",
    seen: false,
    user_id: "",
    user_fullname: "",
    email: "",
    count_escrow_notification: 0,
    count_bank_guarantee_notification: 0,
    count_submission_form_finance_notification: 0,
    loadingTable: true
  }),

  computed: {
    ...mapGetters([
      "layoutConfig",
      "getClasses",
      "currentUserPersonalInfo",
      "currentUserPhoto"
    ]),

    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    totalNotification: function() {
      let countEscrowNotification = this.count_escrow_notification;
      let countBankGuaranteeNotification = this
        .count_bank_guarantee_notification;
      let countSubmissionFormFinanceNotification = this
        .count_submission_form_finance_notification;
      let totalNotification = 0;

      totalNotification += parseInt(
        countEscrowNotification +
          countBankGuaranteeNotification +
          countSubmissionFormFinanceNotification
      );

      return totalNotification;
    },

    config() {
      return this.layoutConfig();
    },

    formTitle() {
      let result = "";

      if (this.editedIndex === -1) {
        result = "BUAT FORM PENGAJUAN";
      } else {
        result = "UBAH FORM PENGAJUAN";
      }

      return result;
    },

    formInput() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.add_submission_form;
      } else {
        result = this.editedItem;
      }

      return result;
    },

    formInputNotClear() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.add_submission_form_not_null;
      } else {
        result = this.editedItem;
      }

      return result;
    },

    formSubmit() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.createDataSubmissionForm;
      } else {
        result = this.updateDataSubmissionForm;
      }

      return result;
    },

    computedDate() {
      return this.formatDate(this.date_submission);
    },

    computedDatePublished() {
      return this.formatDate(this.date_published);
    },

    computedDateEnd() {
      return this.formatDate(this.date_end);
    },

    computedDateModified() {
      return this.formatDate(this.date);
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },

    options: {
      async handler(val) {
        val;
        if (!this.isFirstLoad) await this.dataDoMasuk();
        this.loading = false;
      },
      deep: true
    },

    date_submission() {
      this.formInputNotClear.date_submission = this.formatDate(
        this.date_submission
      );
    },

    date_published() {
      this.formInputNotClear.date_published = this.formatDate(
        this.date_published
      );
    },

    date_end() {
      this.formInputNotClear.date_end = this.formatDate(this.date_end);
    },

    date() {
      this.formInputNotClear.date = this.formatDate(this.date);
    }
  },

  created() {
    this.initHeders();
  },

  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );

    const headerRef = this.$refs["kt_header"];

    headerRef.querySelectorAll("a[class='menu-link']").forEach(item => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });

    this.load();

    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);

    this.user_id = localStorage.getLocalStorage("UID");
    this.user_fullname = localStorage.getLocalStorage("User_Fullname");
    this.email = localStorage.getLocalStorage("User_Email");
    this.user_role = localStorage.getLocalStorage("User_Role");

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Form Pengajuan" }]);
  },

  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },

    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },

    createImage(file) {
      let reader = new FileReader();

      reader.onload = e => {
        this.add_submission_form.path_image_project2 = e.target.result;
        this.count = this.add_submission_form.path_image_project2.length;
      };

      reader.readAsDataURL(file);
    },

    onImageChange(file) {
      this.createImage(file);
    },

    getHeaders() {
      return [
        {
          text: "Id",
          sortable: false,
          value: "submission_number",
          align: " d-none"
        },
        { text: "NO", value: "number", width: "80px" },
        {
          text: "NO PENGAJUAN",
          sortable: true,
          value: "submission_number",
          width: "170px"
        },
        {
          text: "KATEGORI",
          sortable: true,
          value: "transaction_type",
          width: "130px"
        },
        {
          text: "PERUSAHAAN",
          sortable: true,
          value: "workgroup_name",
          width: "150px"
        },
        {
          text: "PERUSAHAAN MITRA",
          sortable: true,
          value: "workgroup_partner_name",
          width: "200px"
        },
        {
          text: "TGL PENGAJUAN",
          sortable: true,
          value: "date_submission",
          width: "170px"
        },
        {
          text: "TGL TERBIT",
          sortable: true,
          value: "date_published",
          width: "140px"
        },
        {
          text: "TGL BERAKHIR",
          sortable: true,
          value: "date_end",
          width: "160px"
        },
        {
          text: "PROYEK",
          sortable: true,
          value: "project_name",
          width: "110px"
        },
        {
          text: "PROYEK FILE",
          sortable: true,
          value: "path_image_project",
          width: "140px"
        },
        {
          text: "NOMINAL",
          sortable: true,
          value: "amount",
          width: "120px"
        },
        {
          text: "EDIT",
          value: "aksi",
          sortable: false
        }
      ];
    },

    loadDate: function() {
      this.date_submission = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date_submission = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    loadDatePublished: function() {
      this.date_published = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date_published = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    loadDateEnd: function() {
      this.date_end = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date_end = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    loadDateModified: function() {
      this.date = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    formatDate(date) {
      if (!date) return null;

      var arr = date.split("-"),
        year = arr[0],
        month = arr[1],
        day = arr[2];
      return `${day}-${month}-${year}`;
    },

    parseDate: function(date) {
      if (!date) return null;

      var arr = date.split("/"),
        day = arr[0],
        month = arr[1],
        year = arr[2];
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    onChangeDate(item) {
      this.$refs.date_submission_form.save(item);
      this.data_submission_form_date.date_submission = item;
    },

    onChangeDatePublished(item) {
      this.$refs.date_published_submission_form.save(item);
      this.data_submission_form_date.date_published = item;
    },

    onChangeDateEnd(item) {
      this.$refs.date_end_submission_form.save(item);
      this.data_submission_form_date.date_end = item;
    },

    createDataSubmissionForm() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "C",
          Token: "C",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          workgroup_id: this.add_submission_form.workgroup_id,
          workgroup_partner_id: this.add_submission_form.workgroup_partner_id,
          project_id: this.add_submission_form.project_id,
          transaction_type: "JBD",
          date_submission: this.data_submission_form_date.date_submission,
          date_published: this.data_submission_form_date.date_published,
          date_end: this.data_submission_form_date.date_end,
          submission_type: "BGOE",
          submission_permission: "OE",
          amount: this.add_submission_form.amount,
          path_image_project: this.add_submission_form.path_image_project,
          path_image_bank_guarantee:
            "http://emitra.asiatechintiraya.id/images/bank-guarantee/.",
          created_by: localStorage.getLocalStorage("User_Fullname"),
          status_cashing: 0,
          status: 1
        };

        var postdata = new FormData();

        for (var key in mydata) {
          postdata.append(key, mydata[key]);
        }

        let contentType = `multipart/form-data; boundary=${postdata._boundary}`;

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          postdata,
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MENAMBAH DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.create_data_submission_form = response.data;
            this.submitted = true;
            this.save("add_submission_form");
            this.loadDataSubmissionForm();
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataSubmissionForm() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "SUBMISSION_STATUS",
          submission_number: this.editedItem.submission_number,
          submission_status: this.editedItem.submission_status
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.update_data_submission_form = response.data;
            this.submitted = true;
            this.save("edit_submission_form");
            this.loadDataSubmissionForm();
          },
          err => {
            err;
          }
        );
      });
    },

    deleteDataSubmissionForm() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "D",
          Token: "D",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          submission_number: this.deletedItem.submission_number
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MENGHAPUS DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.delete_data_submission_form = response.data;
            this.submitted = true;
            this.data_submission_form.splice(this.deletedIndex, 1);
            this.closeDelete();
          },
          err => {
            err;
          }
        );
      });
    },

    dataSubmissionForm() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT_BANK_GUARANTEE",
          submission_type: "BGOE",
          submission_permission: "OE"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_submission_form = response.data;
            this.loadingTable = false;
          },
          err => {
            err;
          }
        );
      });
    },

    seenBill(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "BANK_GUARANTEE_FILE",
          submission_number: item.submission_number,
          submission_type: "BGOE",
          submission_permission: "OE"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_files = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataEscrowPaymentAccepted() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "ESCROW_PAYMENT_ACCEPTED",
          payment_accepted: "E",
          status_receipt: 1,
          status_escrow_accepted: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_escrow_notification = response.data;
            this.count_escrow_notification =
              this.user_role.toLowerCase() === "atasan keuangan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataBankGuaranteeNotification() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "NOTIFICATION_BANK_GUARANTEE",
          status_cashing: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankGuarantee",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_bank_guarantee_notification = response.data;
            this.count_bank_guarantee_notification =
              this.user_role.toLowerCase() === "atasan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataSubmissionFormNotification() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "NOTIFICATION_FOR_BANK_GUARANTEE",
          submission_type: "BGOI",
          status_cashing: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_submission_form_finance_notification = response.data;
            this.count_submission_form_finance_notification =
              this.user_role.toLowerCase() === "atasan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataWorkgroup_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Workgroup",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_workgroup_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataProject_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Project",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_project_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    async load() {
      Promise.all([
        // await this.dataEscrowPaymentAccepted(),
        // await this.dataBankGuaranteeNotification(),
        // await this.dataSubmissionFormNotification(),
        await this.dataSubmissionForm()
      ]).then(function(results) {
        results;
      });
    },

    async loadDataSubmissionForm() {
      Promise.all([await this.dataSubmissionForm()]).then(function(results) {
        results;
      });
    },

    async loadCombobox() {
      Promise.all([
        await this.dataWorkgroup_Combobox(),
        await this.dataProject_Combobox()
      ]).then(function(results) {
        results;
      });
    },

    seenFileBill(item) {
      this.seenBill(item);
      this.dialogSeenBill = true;
    },

    editItem(item) {
      this.editedIndex = this.data_submission_form.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.loadCombobox();
      this.dialog = true;
    },

    deleteItem(item) {
      this.deletedIndex = this.data_submission_form.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.data_submission_form.splice(this.deletedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.add_submission_form.workgroup_id = "";
        this.add_submission_form.workgroup_partner_id = "";
        this.add_submission_form.organizational_unit_id = "";
        this.add_submission_form.work_unit_id = "";
        this.add_submission_form.project_id = "";
        this.add_submission_form.submission_desc = "";
        this.add_submission_form.transaction_type = "";
        this.add_submission_form.amount = 0;
        this.add_submission_form.path_image_project = null;
        this.add_submission_form.path_image_project2 = null;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deletedItem = Object.assign({}, this.defaultItem);
        this.deletedIndex = -1;
      });
    },

    save(formInput) {
      if (formInput == "add_submission_form") {
        if (this.editedIndex > -1) {
          Object.assign(
            this.data_submission_form[this.editedIndex],
            this.add_submission_form
          );
        } else {
          this.data_submission_form.push(this.add_submission_form);
        }
      } else if (formInput == "edit_submission_form") {
        if (this.editedIndex > -1) {
          Object.assign(
            this.data_submission_form[this.editedIndex],
            this.editedItem
          );
        } else {
          this.data_submission_form.push(this.editedItem);
        }
      }

      this.close();
    },

    initHeders() {
      this.headers = this.getHeaders();
    },

    formatNumber
  }
};
</script>
